import { TimeEntries } from '../src/time_entries';
import { DateSetup } from '../src/dateSetup';
import { Shifts } from '../src/shifts';
import { AssignmentActions } from '../src/assignment_actions';
import { SplitShifts } from '../src/split_shifts';

import '../src/css/sticky-table-header.scss';
import '../src/css/dailySheet.scss';

$(document).ready(function() {
  TimeEntries.setup();
  DateSetup.setup();
  Shifts.setup();
  AssignmentActions.setup();
  (new SplitShifts()).setup();
});
