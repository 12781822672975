export class AssignmentActions {
  static setup() {
    if($('#assignment_action_user_to_relieve_id').length) {
      $('#assignment_action_user_to_relieve_id').change(function() {
        AssignmentActions.toggleTimeDisplay();
      });

      this.toggleTimeDisplay();
    }
  }

  static toggleTimeDisplay() {
    let user_to_relive_id = $('#assignment_action_user_to_relieve_id').val();
    let user_id = $('#assignment_action_user_id').val();
      
    if(user_to_relive_id === user_id) {
      $('#assignment_action_time').val('');
      $('.assignment_action_time').hide();
    } else {
      $('.assignment_action_time').show();
    }
  }
}
